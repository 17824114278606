// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Montserrat/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"MontserratRegular\";\n  src: local(\"MontserratRegular\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: bold;\n}\n\nhtml {\n  height: --webkit-fill-available;\n}\n\nbody {\n  height: 100%;\n  height: --webkit-fill-available;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.custom-font {\n  font-family: \"MontserratRegular\";\n}\n", "",{"version":3,"sources":["webpack://./src/assets/styles/index.scss"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC;8DACsE;EACtE,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["@font-face {\n  font-family: \"MontserratRegular\";\n  src: local(\"MontserratRegular\"),\n    url(\"../fonts/Montserrat/Montserrat-Regular.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\nhtml {\n  height: --webkit-fill-available;\n}\n\nbody {\n  height: 100%;\n  height: --webkit-fill-available;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.custom-font {\n  font-family: \"MontserratRegular\";\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
