var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Grow, Stack, styled } from "@mui/material";
import styledC from "styled-components";
import LoginIcon from "@mui/icons-material/Login";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "@utils/helpers/endpoints";
var CustomBox = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(90deg, rgba(85,175,237,1) 0%, rgba(209,179,254,1) 100%)",
    });
});
var CustomH3 = styledC.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 40px;\n  text-align: center;\n"], ["\n  font-size: 40px;\n  text-align: center;\n"])));
function Landing() {
    var navigate = useNavigate();
    function navigateToDashboard() {
        navigate(DASHBOARD);
    }
    return (React.createElement(CustomBox, { spacing: 2 },
        React.createElement(Grow, { in: true },
            React.createElement(CustomH3, { className: "custom-font" },
                "Welcome to SMS Digital FS challenge. ",
                React.createElement("br", null),
                "Click the button to view data")),
        React.createElement(Grow, { in: true },
            React.createElement(Button, { onClick: navigateToDashboard },
                React.createElement(LoginIcon, { className: "custom-font", style: { marginRight: "20px" } }),
                "Enter website"))));
}
export default Landing;
var templateObject_1;
