import Navbar from "@components/Navbar";
import { Stack } from "@mui/material";
import DataTable from "@pages/DataTable";
import React from "react";
function Dashboard() {
    return (React.createElement("div", { style: { height: "100vh" } },
        React.createElement(Navbar, null),
        React.createElement(Stack, { direction: "column", padding: "5% 10%" },
            React.createElement(DataTable, null))));
}
export default Dashboard;
