var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import SortIcon from "@mui/icons-material/Sort";
import { Grow } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { decodeItem } from "@models/item.model";
import { getItems } from "@services/itemServices";
import styled from "styled-components";
import DataTableHeader from "@layouts/section/DataTableHeader";
var Label = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"])));
var columns = [
    { range: 0, id: "city", label: "City", minWidth: 100 },
    { range: 1, id: "startDate", label: "Start\u00a0Date", minWidth: 170 },
    { range: 2, id: "endDate", label: "End\u00a0Date", minWidth: 170 },
    {
        range: 3,
        id: "price",
        label: "Price",
        minWidth: 100,
        format: function (value) { return value.toLocaleString("en-US"); },
    },
    {
        range: 4,
        id: "status",
        label: "Status",
        minWidth: 100,
        align: "right",
        format: function (value) { return value.toLocaleString("en-US"); },
    },
    {
        range: 5,
        id: "color",
        label: "Color",
        minWidth: 100,
        align: "right",
        format: function (value) { return value.toFixed(2); },
    },
];
export default function DataTable() {
    var _a = useState(function () {
        return {
            page: 1,
            limit: 10,
            sort: "-city",
            refresh: undefined,
        };
    }), fields = _a[0], setFields = _a[1];
    var defaultFields = fields;
    var _b = useState([]), items = _b[0], setItems = _b[1];
    var _c = useState(0), page = _c[0], setPage = _c[1];
    // const [hasMore, setHasMore] = useState(false);
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), error = _e[0], setError = _e[1];
    var _f = useState(10), rowsPerPage = _f[0], setRowsPerPage = _f[1];
    var _g = useState(11), showArrowSort = _g[0], setShowArrowSort = _g[1];
    var _h = useState(0), totalRows = _h[0], setTotalRows = _h[1];
    var _j = useState({
        range: -1,
        field: "",
        operator: "+",
    }), sortToggle = _j[0], setSortToggle = _j[1];
    function handleCellSort(sortObject) {
        setItems([]);
        setFields(__assign(__assign({}, fields), { sort: "".concat(sortObject.operator).concat(sortObject.field) }));
    }
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
        setItems([]);
        setFields(__assign(__assign({}, fields), { page: newPage + 1 }));
    };
    var handleChangeRowsPerPage = function (event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setItems([]);
        setFields(__assign(__assign({}, fields), { limit: parseInt(event.target.value, 10) }));
    };
    function generateQuery(fields) {
        var query = "";
        if (fields.page)
            query = query.concat("page=".concat(fields.page, "&"));
        if (fields.limit)
            query = query.concat("limit=".concat(fields.limit, "&"));
        if (fields.sort)
            query = query.concat("sort=".concat(fields.sort, "&"));
        if (fields.startDate)
            query = query.concat("startDate=".concat(fields.startDate, "&"));
        if (fields.endDate)
            query = query.concat("endDate=".concat(fields.endDate, "&"));
        return query.slice(0, query.length - 1);
    }
    useEffect(function () {
        setLoading(true);
        setError(false);
        function exec() {
            return __awaiter(this, void 0, void 0, function () {
                var callbackResponse, callbackResponseOther;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            callbackResponse = function (error, response) {
                                if (response) {
                                    var data = response.data.data
                                        ? response.data.data.map(decodeItem)
                                        : [];
                                    if (data && !(data.length > 0)) {
                                        setLoading(false);
                                        // return;
                                    }
                                    setItems(__spreadArray(__spreadArray([], items, true), data, true));
                                }
                                if (error) {
                                    // TODO: ADD Toast error
                                    console.error("An error occured ", error);
                                }
                            };
                            return [4 /*yield*/, getItems(generateQuery(fields), callbackResponse)];
                        case 1:
                            _a.sent();
                            callbackResponseOther = function (error, response) {
                                var _a;
                                if (response) {
                                    var data = (_a = response.data.data) !== null && _a !== void 0 ? _a : [];
                                    if (data && data.length > 0) {
                                        setTotalRows(fields.page * fields.limit + data.length);
                                    }
                                    else {
                                        setTotalRows((fields.page == 1 ? fields.page : fields.page - 1) *
                                            fields.limit +
                                            (data === null || data === void 0 ? void 0 : data.length));
                                    }
                                }
                                if (error) {
                                    // TODO: ADD Toast error
                                    console.error("An error occured");
                                }
                            };
                            return [4 /*yield*/, getItems(generateQuery(__assign(__assign({}, fields), { page: fields.page + 1 })), callbackResponseOther)];
                        case 2:
                            _a.sent();
                            setLoading(false);
                            return [2 /*return*/];
                    }
                });
            });
        }
        exec();
    }, [fields]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DataTableHeader, { setfields: setFields, setitems: setItems, fields: fields }),
        React.createElement(Grow, { in: true },
            React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, "aria-label": "sticky table" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map(function (column) { return (React.createElement(TableCell, { key: column.id, align: column.align, style: { minWidth: column.minWidth }, onMouseEnter: function () {
                                setShowArrowSort(column.range);
                            }, onMouseLeave: function () {
                                setShowArrowSort(11);
                            }, onClick: function () {
                                setShowArrowSort(11);
                                var operator = "+";
                                var inverseOperator = sortToggle.operator === "-" ? "+" : "-";
                                if (sortToggle.range === column.range)
                                    operator = inverseOperator;
                                setSortToggle({
                                    range: column.range,
                                    operator: operator,
                                    field: column.id,
                                });
                                handleCellSort({
                                    range: column.range,
                                    operator: operator,
                                    field: column.id,
                                });
                            } },
                            React.createElement(Label, { style: {
                                    position: "relative",
                                    float: [4, 5].includes(column.range) ? "right" : "none",
                                } },
                                React.createElement(SortIcon, { style: {
                                        fontSize: "15px",
                                        position: "absolute",
                                        left: "-20px",
                                        color: "gray",
                                        display: showArrowSort === column.range ? "inherit" : "none",
                                    } }),
                                sortToggle.range === column.range &&
                                    sortToggle.operator === "-" ? (React.createElement(ArrowDown, { style: {
                                        fontSize: "15px",
                                        display: sortToggle.range === column.range
                                            ? "inherit"
                                            : "none",
                                    } })) : (React.createElement(ArrowUp, { style: {
                                        fontSize: "15px",
                                        display: sortToggle.range === column.range
                                            ? "inherit"
                                            : "none",
                                    } })),
                                column.label))); }))),
                    React.createElement(TableBody, null, items.map(function (row) {
                        return (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: row.id }, columns.map(function (column) {
                            var value = row[column.id];
                            return (React.createElement(TableCell, { key: column.id, align: column.align }, column.format && typeof value === "number" ? (column.format(value)) : column.id === "color" ? (React.createElement(Box, { sx: {
                                    width: 30,
                                    height: 30,
                                    borderRadius: 50,
                                    backgroundColor: value,
                                    float: "right",
                                } })) : (value)));
                        })));
                    }))))),
        React.createElement(TablePagination, { rowsPerPageOptions: [10, 25, 100], component: "div", count: totalRows, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage })));
}
var templateObject_1;
