var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Stack, styled, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useState } from "react";
var CustomTypography = styled(Typography)(function () { return ({
    marginRight: "20px",
}); });
var CustomDesktopDatePicker = styled(DesktopDatePicker)(function () { return ({
    marginRight: "20px",
}); });
function DataTableHeader(_a) {
    var setfields = _a.setfields, setitems = _a.setitems, fields = _a.fields;
    var _b = useState(null), startDate = _b[0], setStartDate = _b[1];
    var _c = useState(null), endDate = _c[0], setEndDate = _c[1];
    function handleStartDateChange(newValue) {
        setStartDate(newValue);
        setitems([]);
        setfields(__assign(__assign({}, fields), { page: 1, refresh: Math.random(), startDate: newValue != null
                ? moment(newValue).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")
                : undefined }));
    }
    function handleEndDateChange(newValue) {
        setEndDate(newValue);
        setitems([]);
        setfields(__assign(__assign({}, fields), { page: 1, refresh: Math.random(), endDate: newValue != null
                ? moment(newValue).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")
                : undefined }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomTypography, { variant: "h4" }, "Items list"),
        React.createElement(Stack, { direction: "row", alignItems: "stretch", style: { marginBottom: "20px" } },
            React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns },
                React.createElement(CustomDesktopDatePicker, { label: "Start Date", inputFormat: "MM/dd/yyyy", value: startDate, onChange: handleStartDateChange, renderInput: function (params) { return React.createElement(TextField, __assign({}, params)); } }),
                React.createElement(CustomDesktopDatePicker, { label: "End Date", inputFormat: "MM/dd/yyyy", value: endDate, onChange: handleEndDateChange, renderInput: function (params) { return React.createElement(TextField, __assign({}, params)); } })))));
}
export default DataTableHeader;
