var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
function SimpleSpan(props) {
    return (React.createElement("span", { className: "custom-font", onClick: props.onClick, style: __assign({ fontSize: props.fontSize ? props.fontSize : "12px", color: props.color ? props.color : "black", margin: props.margin ? props.margin : "0", padding: props.padding ? props.padding : "0", fontWeight: props.fontWeight ? props.fontWeight : "0", cursor: props.pointer ? "pointer" : "", textDecoration: props.underline ? "underline" : "" }, props.style) }, props.text));
}
export default SimpleSpan;
