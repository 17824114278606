import React from "react";
import { useRoutes } from "react-router-dom";
import Landing from "@layouts/Landing";
import DataTable from "@pages/DataTable";
import { DASHBOARD, DATA, LANDING, NOTFOUND } from "@utils/helpers/paths";
import NotFound from "@pages/NotFound";
import Dashboard from "@layouts/Dashboard";
export default function Router() {
    return useRoutes([
        {
            path: LANDING,
            element: React.createElement(Landing, null),
        },
        { path: DASHBOARD, element: React.createElement(Dashboard, null) },
        { path: DATA, element: React.createElement(DataTable, null) },
        { path: NOTFOUND, element: React.createElement(NotFound, null) },
    ]);
}
