var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import SimpleButton from "@components/commons/SimpleButton";
import SimpleSpan from "@components/commons/SimpleSpan";
import { HOMEPAGE } from "@utils/helpers/endpoints";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100vw;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-around;\n"], ["\n  width: 100vw;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-around;\n"])));
var TextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 70px;\n  cursor: pointer;\n"], ["\n  font-size: 70px;\n  cursor: pointer;\n"])));
function NotFound() {
    var navigate = useNavigate();
    function navigateToHome() {
        navigate(HOMEPAGE);
    }
    return (React.createElement("div", { style: {
            display: "grid",
            placeItems: "center",
            width: "100vw",
            height: "100vh",
        } },
        React.createElement(Container, null,
            React.createElement(TextContainer, null,
                React.createElement(Title, { className: "custom-font" }, "Oops"),
                React.createElement(SimpleSpan, { color: "gray", text: "The page you are looking for does not exist or may have been deleted", fontSize: "1.1rem", fontWeight: "300", margin: "0", pointer: true }),
                React.createElement(SimpleButton, { variant: "contained", text: "Go back to home", onClick: navigateToHome, margin: "40px 0 0 0", width: "300px", style: { backgroundColor: "#47b6e8" } })))));
}
export default NotFound;
var templateObject_1, templateObject_2, templateObject_3;
