var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Button from "@mui/material/Button";
import React from "react";
var SimpleButton = function (props) {
    return (React.createElement(Button, { className: "custom-font", variant: props.variant ? props.variant : "contained", color: props.color ? props.color : "primary", disabled: props.disabled ? props.disabled : false, onClick: props.onClick, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave, style: __assign({ margin: props.margin !== undefined ? props.margin : "0", width: props.width !== undefined ? props.width : "", height: props.height && props.height, float: props.floatRight && props.floatRight ? "right" : "left" }, props.style) },
        React.createElement("div", { style: {
                display: "flex",
                alignItems: "center",
            } },
            props.icon,
            props.text)));
};
export default SimpleButton;
