export default function TableCell(theme) {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    borderRadius: theme.shadows[16],
                    "&::-webkit-scrollbar-thumb": {
                        "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                            cursor: "pointer",
                        },
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 0px rgba(66, 66, 66, 0.4)",
                        backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar": {
                        width: "10px",
                        backgroundColor: theme.palette.grey[500],
                    },
                },
            },
        },
        MuiTableCell: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    "&:hover": {
                        cursor: "pointer",
                    },
                },
            },
        },
    };
}
