import { useMemo } from "react";
// material
import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider, } from "@mui/material/styles";
import React from "react";
import componentsOverride from "./overrides";
import palette from "./palette";
import shadows, { customShadows } from "./shadows";
import typography from "./typography";
export default function ThemeProvider(_a) {
    var children = _a.children;
    var themeOptions = useMemo(function () { return ({
        palette: palette,
        shape: { borderRadius: 8 },
        typography: typography,
        shadows: shadows,
        customShadows: customShadows,
    }); }, []);
    var theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);
    return (React.createElement(StyledEngineProvider, { injectFirst: true },
        React.createElement(MUIThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            children)));
}
