var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Stack, styled } from "@mui/material";
import { HOMEPAGE } from "@utils/helpers/endpoints";
import React from "react";
import { useNavigate } from "react-router-dom";
import SmsDigitalLogo from "../assets/images/sms-digital-logo.png";
import styledC from "styled-components";
var CustomNavBar = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.primary.light,
        height: "10%",
        boxShadow: theme.shadows[18],
        color: "white",
    });
});
var Logo = styledC.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 10%;\n    height: 57%;\n    border-radius: 10px;\n"], ["\n    width: 10%;\n    height: 57%;\n    border-radius: 10px;\n"])));
function Navbar() {
    var navigate = useNavigate();
    function navigateToHome() {
        navigate(HOMEPAGE);
    }
    return (React.createElement(CustomNavBar, { direction: "row", padding: "0 5%", justifyContent: "space-between", alignItems: "center" },
        React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://sms-digital.com" },
            React.createElement(Logo, { src: SmsDigitalLogo, alt: "SMS Digital" })),
        React.createElement(Button, { variant: "contained", color: "warning", onClick: navigateToHome }, "Back to home")));
}
export default Navbar;
var templateObject_1;
