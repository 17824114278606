import { getFormattedDate } from "@utils/helpers/functions";
export var decodeItem = function (requestResponse) {
    return {
        _id: requestResponse._id,
        id: requestResponse.id,
        city: requestResponse.city,
        startDate: getFormattedDate(requestResponse.startDate),
        endDate: getFormattedDate(requestResponse.endDate),
        status: requestResponse.status,
        price: requestResponse.price,
        color: requestResponse.color,
    };
};
