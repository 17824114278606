import ReactDOM from "react-dom/client";
import App from "./App";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Montserrat/Montserrat-Regular.ttf";
import "./assets/styles/index.scss";
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(BrowserRouter, null,
        React.createElement("div", { className: "container" },
            React.createElement(App, null)))));
